
@import "./_reset.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


html,
body {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  overflow: auto;
}

#root {

  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
}

#dialog {

  position: relative;
  display: block;
  z-index: 100;
}

#navigate {

  position: relative;
  display: block;
  z-index: 110;
}
